import {
  AccountDocumentGqlType,
  IncomeSourceTypeEnum,
  Maybe,
  WizardConditionType,
} from 'generated/graphql';
import { AmountsQuestionValues } from 'questionFlow/genericQuestions/AmountsQuestion/types';
import { CompanyDetailsValues } from 'questionFlow/income/CompanyDetails';
import { CompanyReferenceValues } from 'questionFlow/income/CompanyReference';
import { CurrentEmployerQuestionValues } from 'questionFlow/income/CurrentEmployer/types';
import { LoanDetailsValues } from 'questionFlow/income/LoanDetails/types';
import { SavingsAmountValues } from 'questionFlow/income/SavingsAmount';
import { DocumentTypes } from 'questionFlow/income/utilities/documentOptions';
import { YourGuarantorQuestionValues } from 'questionFlow/income/yourGuarantor/types';
import { YourSalaryQuestionValues } from 'questionFlow/income/YourSalary/types';
import { QuestionComponentProps } from 'questionFlow/types';

//Question Answer enums
export enum whoIsPayingEnum {
  ME = 'me',
  OTHER = 'other',
}

export enum describeJobEnum {
  EMPLOYED = 'employed',
  SELF_EMPLOYED = 'self-employed',
}

export enum describeEmploymentEnum {
  PERMANENTLY_EMPLOYED = 'permanentlyEmployed',
  PART_TIME_EMPLOYED = 'partTimeEmployed',
  ZERO_HOURS_CONTRACT = 'zeroHoursContract',
}

export enum YesNoEnum {
  YES = 'yes',
  NO = 'no',
}

export enum someoneElseEnum {
  ZEROPERCENTSHARE = 'zeroPercentShare',
  GUARANTOR = 'guarantor',
}

export enum StepNamesDefaultIncome {
  whoIsPaying = 'whoIsPaying',
  incomeType = 'incomeType',
  someoneElse = 'someoneElse',
  currentEmployer = 'currentEmployer',
  companyReference = 'companyReference',
  yourIncome = 'yourIncome',
  yourSalary = 'yourSalary',
  loanDetails = 'loanDetails',
  yourLoan = 'yourLoan',
  companyDetails = 'companyDetails',
  openBanking = 'openBanking',
  feedback = 'feedback',
  documents = 'documents',
  upload = 'upload',
  nonOpenBankingNotice = 'nonOpenBankingNotice',
  uploadBankStatements = 'uploadBankStatements',
  bankStatementSignposting = 'bankStatementSignposting',
  documentSignposting = 'documentSignposting',
  noDocumentsNotice = 'noDocumentsNotice',
  success = 'success',
  successAddIncome = 'successAddIncome',
  error = 'error',
  savingsAmount = 'savingsAmount',
  yourGuarantor = 'yourGuarantor',
  additionalIncome = 'additionalIncome',
  guarantorConfirmation = 'guarantorConfirmation',
  zeroPercentNotice = 'zeroPercentNotice',
  incomeUploadSuccess = 'incomeUploadSuccess',
  pensionDetails = 'pensionDetails',
  pensionAmount = 'pensionAmount',
  benefitDetails = 'benefitDetails',
  benefitAmount = 'benefitAmount',
}

// for both income with and without guarantor
export enum incomeConditionTypesEnum {
  ADD_MANUAL_DOC_UPLOAD = 'ADD_MANUAL_DOC_UPLOAD',
  ALLOWED_INCOME_TYPES = 'ALLOWED_INCOME_TYPES',
  INCOME_START_STEP = 'INCOME_START_STEP',
  ALLOWED_OTHER_PAYERS = 'ALLOWED_OTHER_PAYERS',
  SKIP_OPEN_BANKING = 'SKIP_OPEN_BANKING',
  PERM_EMPLOYMENT_DOCUMENT_TYPES = 'PERM_EMPLOYMENT_DOCUMENT_TYPES',
}

export enum incomeStartStepConditionEnum {
  INCOME_TYPE = StepNamesDefaultIncome.incomeType,
  OPEN_BANKING = StepNamesDefaultIncome.openBanking,
  DOCUMENT_CHOICE = StepNamesDefaultIncome.documents,
  UPLOAD = StepNamesDefaultIncome.upload,
}

export interface IncomeSearchParams {
  uuid: String | null;
  success: boolean | null;
}

export interface IncomeSectionMetaData {
  email: string;
  conditions?: WizardConditionType[];
}

export interface DefaultIncomeValuesType extends Partial<Record<StepNamesDefaultIncome, any>> {
  uuid: string | null;
  meetsAffordabilityCriteria: boolean;
  [StepNamesDefaultIncome.whoIsPaying]: whoIsPayingEnum | '';
  [StepNamesDefaultIncome.incomeType]: Maybe<IncomeSourceTypeEnum>;
  [StepNamesDefaultIncome.someoneElse]: someoneElseEnum | '';
  [StepNamesDefaultIncome.companyReference]: CompanyReferenceValues;
  [StepNamesDefaultIncome.companyDetails]: CompanyDetailsValues;
  [StepNamesDefaultIncome.yourIncome]: AmountsQuestionValues;
  [StepNamesDefaultIncome.savingsAmount]: SavingsAmountValues;
  [StepNamesDefaultIncome.loanDetails]: LoanDetailsValues;
  [StepNamesDefaultIncome.yourSalary]: YourSalaryQuestionValues;
  [StepNamesDefaultIncome.yourLoan]: AmountsQuestionValues;
  [StepNamesDefaultIncome.currentEmployer]: CurrentEmployerQuestionValues;
  [StepNamesDefaultIncome.yourGuarantor]?: YourGuarantorQuestionValues;
  [StepNamesDefaultIncome.documents]: DocumentTypes | '' | 'none';
  [StepNamesDefaultIncome.upload]?: AccountDocumentGqlType[];
}

export interface DefaultIncomeQuestionComponentProps
  extends QuestionComponentProps<IncomeSectionMetaData, DefaultIncomeValuesType> {}
